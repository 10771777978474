<template>
  <main>
    <div v-if="userAccount">
      <div>
        <h6>Account</h6>
        <div class="error">{{ errorProfile }}</div>
        <div class="form-outline mb-4 container-fluid">
          <label>Name</label>
          <div class="row">
            <div class="col-8">
              <input
                type="text"
                v-model="userAccount.name"
                class="form-control"
              />
            </div>
            <div class="col-3">
              <div
                v-if="isUpdateProfilePending"
                class="spinner-border"
                role="status"
              >
                <span class="visually-hidden">Updating...</span>
              </div>
              <div v-else>
                <span class="btn btn-primary" @click="updateDisplayName">
                  Update Name
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div v-if="user" class="row">
            <label>Current Sign-In Email</label>
            <b>{{ user.email }}</b>
          </div>
        </div>
        <div class="container-fluid border p-2 mb-4 bg-light ">
          <div class="form-outline mb-4">
            <label>New Email</label>
            <div class="row">
              <div class="col-8">
                <input
                  type="text"
                  v-model="userAccount.email"
                  class="form-control"
                />
              </div>
              <div class="col-3">
                <div
                  v-if="isUpdateEmailPending"
                  class="spinner-border"
                  role="status"
                >
                  <span class="visually-hidden">Updating...</span>
                </div>
                <div v-else>
                  <span class="btn btn-primary" @click="updateEmail">
                    Update Email
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isPasswordShowing" class="form-outline mb-4">
            <label>Enter your current password to change your email</label>
            <div class="row align-items-start">
              <div class="col-8">
                <input
                  type="text"
                  placeholder="Password"
                  v-model="password"
                  class="form-control"
                />
              </div>
              <div class="col">
                <span
                  class="input-group-text btn btn-primary"
                  @click="isPasswordShowing = false"
                  ><i class="bi bi-eye-slash"></i>
                </span>
              </div>
            </div>
          </div>
          <div v-else class="form-outline mb-4">
            <label>Enter your current password to change your email</label>
            <div class="row align-items-start">
              <div class="col-8">
                <input
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  class="form-control"
                />
              </div>
              <div class="col">
                <span
                  class="input-group-text btn btn-primary"
                  @click="isPasswordShowing = true"
                  ><i class="bi bi-eye"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="error">{{ errorLogin }}</div>
          <div class="form-outline mb-4">
            <div v-if="user && user.emailVerified">
              Your email has been verified.
            </div>
            <div v-else>
              <div v-if="emailSent">
                <p>
                  Your email validation message has been sent. Check your email
                  and follow the instructions to complete the validation
                  process.
                </p>
              </div>
              <div v-else>
                <p>
                  Your email is not verified. You can verify your email by
                  clicking the button below and following the instructions you
                  receive in the email.
                </p>
                <div v-if="isEmailSending" class="spinner-border" role="status">
                  <span class="visually-hidden">Updating...</span>
                </div>
                <div v-else>
                  <span
                    class="btn btn-primary"
                    @click="requestVerificationEmail"
                  >
                    Request Email Address Validation Email
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="error">{{ errorVerify }}</div>
        </div>
      </div>
      <form @submit.prevent="handleSave" class="shadow edit-form">
        <h6>Full Legal Name</h6>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col">
              <div class="form-outline mb-4">
                <input
                  type="text"
                  placeholder="First Name"
                  v-model="userAccount.firstName"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-outline mb-4">
                <input
                  type="text"
                  placeholder="Middle Name"
                  v-model="userAccount.middleName"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-outline mb-4">
                <input
                  type="text"
                  placeholder="Last Name"
                  v-model="userAccount.lastName"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-outline mb-4">
                <input
                  type="text"
                  placeholder="Suffix"
                  v-model="userAccount.suffix"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-outline mb-4 container-fluid">
          <div class="row">
            <div class="col-md-4">
              <label>Date of Birth</label>
              <input
                type="date"
                v-model="userAccount.dateOfBirth"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-4">
              <label>Phone Number</label>
              <input
                type="text"
                v-model="userAccount.phone"
                class="form-control"
                required
              />
            </div>
            <div class="col-md-4">
              <label>Alternate Phone</label>
              <input
                type="text"
                v-model="userAccount.phone2"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <h6>Mailing Address</h6>
        <AddressFieldGroup
          v-model:addressLine1="userAccount.streetAddress1"
          v-model:addressLine2="userAccount.streetAddress2"
          v-model:city="userAccount.city"
          v-model:state="userAccount.region"
          v-model:postalCode="userAccount.postalCode"
          v-model:country="userAccount.country"
        />
        <div class="error">{{ formError }}</div>
        <div class="row text-center">
          <div class="col">
            <div v-if="!isPending">
              <button class="btn btn-primary">Save Contact Information</button>
            </div>
            <div v-else>
              <button class="btn btn-primary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
  <Footer v-if="userAccount" />
</template>

<script>
import { ref } from "@vue/reactivity";
import getUser from "@/composables/getUser";
import useUser from "@/composables/useUser";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import useLogin from "@/composables/useLogin";
import AddressFieldGroup from "@/components/AddressFieldGroup.vue";

export default {
  components: { AddressFieldGroup, Footer },
  setup() {
    const errorVerify = ref(null);
    const emailSent = ref(false);
    const isEmailSending = ref(false);
    const isPending = ref(false);
    const isUpdateProfilePending = ref(false);
    const isUpdateEmailPending = ref(false);
    const isPasswordShowing = ref(false);
    const formError = ref(null);
    const password = ref(null);

    const actionCodeSettings = {
      url: `${process.env.VUE_APP_HOST_NAME}account`
    };
    const { user } = getUser();
    const {
      error: errorProfile,
      updateProfileName,
      updateProfileEmail
    } = useUser();
    const { document: userAccount } = getDocument("users", user.value.uid);
    const { error: errorOffering, updateDoc: updateUserAccount } = useDocument(
      "users",
      user.value.uid
    );

    const updateDisplayName = async () => {
      isUpdateProfilePending.value = true;
      await updateProfileName(userAccount.value.name);
      if (errorProfile.value) {
        console.log(
          `Error updating user profile display name ${errorProfile.value}`
        );
        isUpdateProfilePending.value = false;
      } else {
        await updateUserAccount({
          name: userAccount.value.name,
          editedAt: timestamp()
        });
        isUpdateProfilePending.value = false;
      }
    };

    const { error: errorLogin, login } = useLogin();

    const updateEmail = async () => {
      isUpdateEmailPending.value = true;
      if (password.value) {
        await login(user.value.email, password.value);
        if (!errorLogin.value) {
          await updateProfileEmail(userAccount.value.email);
          userAccount.value.email = user.value.email;
          if (errorProfile.value) {
            console.log(
              `Error updating user profile email ${errorProfile.value}`
            );
            isUpdateEmailPending.value = false;
          } else {
            await updateUserAccount({
              email: userAccount.value.email,
              editedAt: timestamp()
            });
            isUpdateEmailPending.value = false;
          }
        }
        password.value = "";
      } else {
        errorLogin.value = "Your password is empty.";
      }
      isUpdateEmailPending.value = false;
    };

    const requestVerificationEmail = async () => {
      try {
        errorVerify.value = null;
        console.log("Request Email");
        await user.value.sendEmailVerification(actionCodeSettings);
        emailSent.value = true;
      } catch (err) {
        errorVerify.value = `Email Verification Error: ${err.message}`;
      }
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        await updateUserAccount({
          firstName: userAccount.value.firstName,
          middleName: userAccount.value.middleName
            ? userAccount.value.middleName
            : "",
          lastName: userAccount.value.lastName,
          suffix: userAccount.value.suffix ? userAccount.value.suffix : "",
          streetAddress1: userAccount.value.streetAddress1
            ? userAccount.value.streetAddress1
            : "",
          streetAddress2: userAccount.value.streetAddress2
            ? userAccount.value.streetAddress2
            : "",
          city: userAccount.value.city ? userAccount.value.city : "",
          region: userAccount.value.region ? userAccount.value.region : "",
          postalCode: userAccount.value.postalCode
            ? userAccount.value.postalCode
            : "",
          country: userAccount.value.country ? userAccount.value.country : "",
          phone: userAccount.value.phone ? userAccount.value.phone : "",
          phone2: userAccount.value.phone2 ? userAccount.value.phone2 : "",
          dateOfBirth: userAccount.value.dateOfBirth
            ? userAccount.value.dateOfBirth
            : "",
          editedAt: timestamp()
        });

        isPending.value = false;
        if (errorOffering.value) {
          formError.value = errorOffering.value;
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };
    return {
      user,
      userAccount,
      isPending,
      isUpdateProfilePending,
      isUpdateEmailPending,
      formError,
      errorLogin,
      handleSave,
      updateDisplayName,
      updateEmail,
      errorProfile,
      password,
      isPasswordShowing,
      emailSent,
      isEmailSending,
      requestVerificationEmail,
      errorVerify
    };
  }
};
</script>

<style>
</style>