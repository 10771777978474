<template>
  <!-- <div class="form-outline mb-4 container-fluid">
    <label>Field Group Origin</label>
    <input type="text" class="form-control" ref="streetRef" />
  </div> -->

  <div class="form-outline mb-4 container-fluid">
    <label>Address Line 1</label>
    <input
      type="text"
      @input="$emit('update:addressLine1', $event.target.value)"
      :value="addressLine1"
      class="form-control"
      ref="streetRef"
      required
    />
  </div>
  <div class="form-outline mb-4 container-fluid">
    <label>Address Line 2</label>
    <input
      type="text"
      @input="$emit('update:addressLine2', $event.target.value)"
      :value="addressLine2"
      class="form-control"
    />
  </div>
  <div class="form-outline mb-4 container-fluid">
    <div class="row">
      <div class="col-md-6">
        <label>City</label>
        <input
          type="text"
          @input="$emit('update:city', $event.target.value)"
          :value="city"
          class="form-control"
          required
        />
      </div>
      <div class="col">
        <label>State</label>
        <input
          type="text"
          @input="$emit('update:state', $event.target.value)"
          :value="state"
          class="form-control"
          required
        />
      </div>
      <div class="col">
        <label>Zip Code</label>
        <input
          type="text"
          @input="$emit('update:postalCode', $event.target.value)"
          :value="postalCode"
          class="form-control"
          required
        />
      </div>
      <div class="col">
        <label>Country</label>
        <input
          type="text"
          @input="$emit('update:country', $event.target.value)"
          :value="country"
          class="form-control"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useScript from "@/composables/useScript";
import { onMounted, onUnmounted } from "@vue/runtime-core";

export default {
  props: {
    addressLine1: {
      type: String,
      default: ""
    },
    addressLine2: {
      type: String,
      default: ""
    },
    city: {
      type: String,
      default: ""
    },
    state: {
      type: String,
      default: ""
    },
    postalCode: {
      type: String,
      default: ""
    },
    country: {
      type: String,
      default: "US"
    }
  },
  setup(props, context) {
    const streetRef = ref(null);

    let autocomplete;

    onMounted(async () => {
      if (!(window.google && window.google.maps)) {
        console.log("Loading Google");
        await useScript(
          "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBDLErLCcZUIIr8mqdP-O90eR2xZFeamXY"
        );
      } else {
        console.log("Google Maps is already loaded");
      }
      autocomplete = new window.google.maps.places.Autocomplete(
        streetRef.value,
        {
          types: ["address"],
          fields: ["address_components"]
        }
      );

      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          console.log(autocomplete.getPlace());
          const mapping = {
            street_number: "update:streetNumber",
            route: "update:street",
            locality: "update:city",
            postal_code: "update:postalCode",
            administrative_area_level_1: "update:state",
            country: "update:country"
          };

          for (const type in mapping) {
            context.emit(mapping[type], "");
          }

          let place = {
            address_components: [],
            ...autocomplete.getPlace()
          };

          let streetNumber = "";
          let streetName = "";

          place.address_components.forEach(component => {
            component.types.forEach(type => {
              //if (mapping.hasOwnProperty(type)) {
              if (Object.prototype.hasOwnProperty.call(mapping, type)) {
                console.log(
                  `Mapping: ${mapping[type]} Component: ${component.long_name}`
                );

                switch (mapping[type]) {
                  case "update:streetNumber": {
                    streetNumber = component.long_name;
                    console.log(`Street Number ${streetNumber}`);
                    context.emit(
                      "update:addressLine1",
                      `${streetNumber} ${streetName}`
                    );
                    break;
                  }

                  case "update:street": {
                    streetName = component.short_name;
                    console.log(`Street Name: ${streetName}`);
                    context.emit(
                      "update:addressLine1",
                      `${streetNumber} ${streetName}`
                    );
                    break;
                  }
                  default:
                    context.emit(mapping[type], component.short_name);
                }
              }
            });
          });
        }
      );
    });

    onUnmounted(() => {
      if (autocomplete) {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      }
    });

    return { streetRef };
  }
};
</script>

<style>
</style>