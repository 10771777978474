import { onUnmounted } from "@vue/runtime-core";

export default function (src) {
    return new Promise((resolve, reject) => {
        console.log("In Promise");
        let script = document.querySelector(`script[src="${src}"]`);
        if (!script) {
            script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.setAttribute("data-status", "loading");
            document.head.append(script);
        }
        if (script.getAttribute("data-status") === "loaded") {
            console.log("Loading Resolve");
            resolve();
        }

        function onScriptLoad() {
            console.log("onScriptLoad");
            script.setAttribute("data-status", "loaded");
            resolve();
        }

        function onScriptError() {
            console.log("onScriptError");
            script.setAttribute("data-status", "error");
            reject();
        }

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        onUnmounted(() => {
            if (document.head.contains(script)) {
                script.removeEventListener("load", onScriptLoad);
                script.removeEventListener("error", onScriptError);
                document.head.removeChild(script);
            }
        });
    });
}